import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const SingleUser = lazy(() => import('../modules/apps/user-profile'))
  const Listing = lazy(() => import('../modules/Listings'))
  const SingleListing = lazy(() => import('../modules/Listings/SingleListing'))
  const Settings = lazy(() => import('../modules/Settings'))
  const Bookings = lazy(() => import('../modules/Bookings'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/listings' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='metrics' element={<DashboardWrapper />} />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
  
        <Route
          path='users'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route path='users/:id'
          element={
            <SuspensedView>
              <SingleUser />
            </SuspensedView>
          }
        />

         <Route
          path='listings'
          element={
            <SuspensedView>
              <Listing />
            </SuspensedView>
          }
        />
          <Route
          path='listings/:id'
          element={
            <SuspensedView>
              <SingleListing />
            </SuspensedView>
          }
        />
         <Route
          path='bookings/*'
          element={
            <SuspensedView>
              <Bookings />
            </SuspensedView>
          }
        />
          <Route path="account/*"
          element={
            <SuspensedView>
              <Settings />
              </SuspensedView>  
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
