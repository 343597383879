import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
      <I18nProvider>
        {/* <LayoutProvider> */}
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
          <ToastContainer limit={1}/>
        {/* </LayoutProvider> */}
      </I18nProvider>

  )
}

export {App}
