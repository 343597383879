/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  items?: number
}

const ListsWidget4: React.FC<Props> = ({className, items = 6}) => {
  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Most Booked Locations</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Latest tech trends</span> */}
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Item */}
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/angular-icon.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                Truck Parking Space 1
              </a>
              <span className='text-muted fw-semibold d-block fs-7'>515 Park Avenue, New York</span>
            </div>
            <span className='badge badge-light fw-bold my-2'>Active</span>
          </div>
          {/* end::Section */}
        </div>
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/react.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                Truck Parking Space 2
              </a>
              <span className='text-muted fw-semibold d-block fs-7'>515 Park Avenue, New York</span>
            </div>
            <span className='badge badge-light fw-bold my-2'>Active</span>
          </div>
          {/* end::Section */}
        </div>
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/gulp.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                Truck Parking Space 3
              </a>
              <span className='text-muted fw-semibold d-block fs-7'>515 Park Avenue, New York</span>
            </div>
            <span className='badge badge-light fw-bold my-2'>Active</span>
          </div>
          {/* end::Section */}
        </div>
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/vue-9.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                Truck Parking Space 4
              </a>
              <span className='text-muted fw-semibold d-block fs-7'>515 Park Avenue, New York</span>
            </div>
            <span className='badge badge-light fw-bold my-2'>Active</span>
          </div>
          {/* end::Section */}
        </div>
        <div className='d-flex align-items-sm-center mb-7'>
          {/* begin::Symbol */}
          <div className='symbol symbol-50px me-5'>
            <span className='symbol-label'>
              <img
                src={toAbsoluteUrl('/media/svg/brand-logos/leaf.svg')}
                className='h-50 align-self-center'
                alt=''
              />
            </span>
          </div>
          {/* end::Symbol */}
          {/* begin::Section */}
          <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
            <div className='flex-grow-1 me-2'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                Truck Parking Space 5
              </a>
              <span className='text-muted fw-semibold d-block fs-7'>515 Park Avenue, New York</span>
            </div>
            <span className='badge badge-light fw-bold my-2'>Active</span>
          </div>
          {/* end::Section */}
        </div>

    
       


  
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget4}
